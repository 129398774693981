import { Directive, ElementRef, inject, Input } from '@angular/core';

/**
 * Button UI
 * This directive is used to style a button element as an icon.
 *
 * @example
 *  Simple example:
 * 
 * Options to use:
 *   <button epg-button-icon>
        <span class="p-button-icon pi pi-search"></span>
      </button>

* Usage with epg-button directive:
  <button epg-button epg-button-icon shape="text" ><span class="p-button-icon pi pi-search"></span></button>
 *   
 */
@Directive({
    selector: '[epg-button-icon]',
    standalone: true
  })
  export class UiEpgButtonIconDirective {

    el = inject(ElementRef);

    ngOnInit(): void {
      this.el.nativeElement.classList.add('p-element' , 'p-button', 'p-component', 'p-button-icon-only');
    }
}